:host {
  height: 100%;
}

.produtos {
  display: grid;
  // justify-items: center;
  // justify-items: stretch;
  grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
  text-align: center;
  list-style-type: none;
  padding: 6px;
  // background-color: blue;
  grid-gap: 4px;
}

.produto {
  background-color: white;
  // border: 1px solid #ddd;
  border: 1px solid #eee;
  // flex-direction: column;
  padding: 6px;
  // max-height: 300px;
  height: 100%;
  // transform: scale(.95);
  transition: all 0.5s;
  border-radius: 12px;
}

.produto .nome {
  line-height: 1.1rem;
}

.produto .footer {
  height: 80px;
  // background: red;
}
