/* Fonts */
@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

/* 3rd */
// @import '~ngx-red-zoom/styles/base.scss';
// @import '~ngx-red-zoom/styles/style-magnifier.scss';

/* Custom CSS */
@import "./assets/styles/misc.scss";
@import "./assets/styles/bs5.scss";
/* @import "./assets/styles/switch-slider.css"; */
@import "./assets/styles/mat.scss";
@import "./assets/styles/dashboard.scss";
@import "./assets/styles/w3.scss";
@import "./assets/styles/cards.scss";
// @import "./contas.scss";
@import "./pedidos.scss";
@import "./produtos.scss";
// @import "./lojas.scss";

/* Importing Bootstrap SCSS file. */
// @import "~bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
// @import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

/* misc */
* {
  font-family: "Montserrat", sans-serif;
  box-sizing: border-box;
}

body {
  // margin: 0px;
  // height: 0px;
  // box-sizing: border-box;
  background-color: whitesmoke;
  // min-height: 100vh;
  // width: 100vw;
}

.page {
  min-height: 100vh;
}

.box {
  background-color: white;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  padding: 2rem;
}

.mat-expansion-panel-body {
  padding: 0 !important;
}

.toggle-wrapper {
  width: 180px;
  // background-color: red;
}

/* .sep-btn */

.sep-btn {
  position: relative;
  border: 1px solid silver;
  // min-height: 52px;
  border-radius: 8px;
  background-color: #f8f9fa;
  transition: all .2s ease-in-out;
}

.sep-btn.sel {
  border: 1px solid var(--ion-color-primary);
  // color: #f8f9fa;
  background-color: var(--ion-color-primary-shade);
  color: var(--ion-color-primary-contrast);
}

/* .sep-btn:hover {
  // opacity: .5;
  // transform: scale(1.1);
  display: inline-block;
  animation: wiggle 2.7s infinite;
} */
